import React from "react"

import blogone from "../../img/1.jpg"
import blogtwo from "../../img/2.jpg"
import blogthree from "../../img/3.jpg"
import blogfour from "../../img/4.jpg"
import blogfive from "../../img/5.jpg"
import blogsix from "../../img/6.jpg"

const Sliderabout = () => {
  return (
    <>
      <section>
        <div className="rs-inner-blog pt-120 pb-105 md-pt-80 md-pb-80 pt-4">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 mb-4">
                <div className="row">
                  <div className="col-lg-12 mb-50">
                    <div className="blog-item">
                      <div className="blog-img">
                        <a href="blog-single">
                          <img src={blogone} alt="" />
                        </a>
                      </div>
                      <div className="blog-content">
                        <h3 className="blog-title">
                          <a href="blog-single">
                            We really appreciate content about the construction
                          </a>
                        </h3>
                        <div className="blog-meta">
                          <ul className="btm-cate">
                            <li>
                              <div className="author">
                                <i className="fi fi-rr-user"></i> Devsdesign
                              </div>
                            </li>
                            <li>
                              <div className="blog-date">
                                <i className="fi fi-rr-calendar"></i> May 26,
                                2022
                              </div>
                            </li>
                            <li>
                              <div className="tag-line">
                                <i className="fi fi-rr-book"></i>
                                <a href="/">Educational</a>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="blog-desc">
                          The brand has responded by partnering with skincare
                          experts on social and web content, providing
                          ingredient deep-dives and re-sharing followers’ tips
                          and tricks on social media. The trand has responded by
                          partnering with skincare experts on social and web
                          content, providing ingredient deep-dives and...
                        </div>
                        <div className="blog-button inner-btn">
                          <a className="blog-btn" href="blog-single">
                            Continue Reading
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 mb-50">
                    <div className="blog-item">
                      <div className="blog-img">
                        <a href="blog-single">
                          <img src={blogtwo} alt="" />
                        </a>
                      </div>
                      <div className="blog-content">
                        <h3 className="blog-title">
                          <a href="blog-single">
                            Best practices construction law on construction
                          </a>
                        </h3>
                        <div className="blog-meta">
                          <ul className="btm-cate">
                            <li>
                              <div className="author">
                                <i className="fi fi-rr-user"></i> Devsdesign
                              </div>
                            </li>
                            <li>
                              <div className="blog-date">
                                <i className="fi fi-rr-calendar"></i> May 26,
                                2022
                              </div>
                            </li>
                            <li>
                              <div className="tag-line">
                                <i className="fi fi-rr-book"></i>
                                <a href="/">Educational</a>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="blog-desc">
                          The brand has responded by partnering with skincare
                          experts on social and web content, providing
                          ingredient deep-dives and re-sharing followers’ tips
                          and tricks on social media. The trand has responded by
                          partnering with skincare experts on social and web
                          content, providing ingredient deep-dives and...
                        </div>
                        <div className="blog-button inner-btn">
                          <a className="blog-btn" href="blog-single">
                            Continue Reading
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 mb-50">
                    <div className="blog-item">
                      <div className="blog-img">
                        <a href="blog-single">
                          <img src={blogthree} alt="" />
                        </a>
                      </div>
                      <div className="blog-content">
                        <h3 className="blog-title">
                          <a href="blog-single">
                            Although many people may overlook the need
                          </a>
                        </h3>
                        <div className="blog-meta">
                          <ul className="btm-cate">
                            <li>
                              <div className="author">
                                <i className="fi fi-rr-user"></i> Devsdesign
                              </div>
                            </li>
                            <li>
                              <div className="blog-date">
                                <i className="fi fi-rr-calendar"></i> May 26,
                                2022
                              </div>
                            </li>
                            <li>
                              <div className="tag-line">
                                <i className="fi fi-rr-book"></i>
                                <a href="/">Educational</a>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="blog-desc">
                          The brand has responded by partnering with skincare
                          experts on social and web content, providing
                          ingredient deep-dives and re-sharing followers’ tips
                          and tricks on social media. The trand has responded by
                          partnering with skincare experts on social and web
                          content, providing ingredient deep-dives and...
                        </div>
                        <div className="blog-button inner-btn">
                          <a className="blog-btn" href="blog-single">
                            Continue Reading
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 mb-50">
                    <div className="blog-item">
                      <div className="blog-img">
                        <a href="blog-single">
                          <img src={blogfour} alt="" />
                        </a>
                      </div>
                      <div className="blog-content">
                        <h3 className="blog-title">
                          <a href="blog-single">
                            Construction executive holds a very special place
                          </a>
                        </h3>
                        <div className="blog-meta">
                          <ul className="btm-cate">
                            <li>
                              <div className="author">
                                <i className="fi fi-rr-user"></i> Devsdesign
                              </div>
                            </li>
                            <li>
                              <div className="blog-date">
                                <i className="fi fi-rr-calendar"></i> May 26,
                                2022
                              </div>
                            </li>
                            <li>
                              <div className="tag-line">
                                <i className="fi fi-rr-book"></i>
                                <a href="/">Educational</a>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="blog-desc">
                          The brand has responded by partnering with skincare
                          experts on social and web content, providing
                          ingredient deep-dives and re-sharing followers’ tips
                          and tricks on social media. The trand has responded by
                          partnering with skincare experts on social and web
                          content, providing ingredient deep-dives and...
                        </div>
                        <div className="blog-button inner-btn">
                          <a className="blog-btn" href="blog-single">
                            Continue Reading
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 mb-50">
                    <div className="blog-item">
                      <div className="blog-img">
                        <a href="blog-single">
                          <img src={blogfive} alt="" />
                        </a>
                      </div>
                      <div className="blog-content">
                        <h3 className="blog-title">
                          <a href="blog-single">
                            This is another massive sites with a ton of
                            information
                          </a>
                        </h3>
                        <div className="blog-meta">
                          <ul className="btm-cate">
                            <li>
                              <div className="author">
                                <i className="fi fi-rr-user"></i> Devsdesign
                              </div>
                            </li>
                            <li>
                              <div className="blog-date">
                                <i className="fi fi-rr-calendar"></i> May 26,
                                2022
                              </div>
                            </li>
                            <li>
                              <div className="tag-line">
                                <i className="fi fi-rr-book"></i>
                                <a href="/">Educational</a>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="blog-desc">
                          The brand has responded by partnering with skincare
                          experts on social and web content, providing
                          ingredient deep-dives and re-sharing followers’ tips
                          and tricks on social media. The trand has responded by
                          partnering with skincare experts on social and web
                          content, providing ingredient deep-dives and...
                        </div>
                        <div className="blog-button inner-btn">
                          <a className="blog-btn" href="blog-single">
                            Continue Reading
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="blog-item">
                      <div className="blog-img">
                        <a href="blog-single">
                          <img src={blogsix} alt="" />
                        </a>
                      </div>
                      <div className="blog-content">
                        <h3 className="blog-title">
                          <a href="blog-single">
                            This is another massive sites with a ton of
                            information
                          </a>
                        </h3>
                        <div className="blog-meta">
                          <ul className="btm-cate">
                            <li>
                              <div className="author">
                                <i className="fi fi-rr-user"></i> Devsdesign
                              </div>
                            </li>
                            <li>
                              <div className="blog-date">
                                <i className="fi fi-rr-calendar"></i> May 26,
                                2022
                              </div>
                            </li>
                            <li>
                              <div className="tag-line">
                                <i className="fi fi-rr-book"></i>
                                <a href="/">Educational</a>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="blog-desc">
                          The brand has responded by partnering with skincare
                          experts on social and web content, providing
                          ingredient deep-dives and re-sharing followers’ tips
                          and tricks on social media. The trand has responded by
                          partnering with skincare experts on social and web
                          content, providing ingredient deep-dives and...
                        </div>
                        <div className="blog-button inner-btn">
                          <a className="blog-btn" href="blog-single">
                            Continue Reading
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 ">
                <div className="widget-area">
                  <div className="search-widget mb-50">
                    <div className="search-wrap">
                      <input
                        type="search"
                        placeholder="Searching..."
                        name="s"
                        className="search-input"
                        value="Searching"
                      />
                      <button type="submit" value="Search">
                        <i className="flaticon-search"></i>
                      </button>
                    </div>
                  </div>
                  <div className="recent-posts mb-5">
                    <div className="widget-title">
                      <h3 className="title">Recent Posts</h3>
                    </div>
                    <div className="recent-post-widget no-border">
                      <div className="post-img">
                        <a href="blog-single">
                          <img src={blogone} alt="" />
                        </a>
                      </div>
                      <div className="post-desc">
                        <a href="blog-single">
                          We really appreciate content about the construction
                        </a>
                        <span className="date-post">
                          {" "}
                          <i className="fi fi-rr-calendar"></i>May 26, 2022
                        </span>
                      </div>
                    </div>
                    <div className="recent-post-widget">
                      <div className="post-img">
                        <a href="blog-single">
                          <img src={blogtwo} alt="" />
                        </a>
                      </div>
                      <div className="post-desc">
                        <a href="blog-single">
                          Best practices construction law on construction
                        </a>
                        <span className="date-post">
                          {" "}
                          <i className="fi fi-rr-calendar"></i>May 26, 2022
                        </span>
                      </div>
                    </div>
                    <div className="recent-post-widget">
                      <div className="post-img">
                        <a href="blog-single">
                          <img src={blogthree} alt="" />
                        </a>
                      </div>
                      <div className="post-desc">
                        <a href="blog-single">
                          Although many people may overlook the need
                        </a>
                        <span className="date-post">
                          {" "}
                          <i className="fi fi-rr-calendar"></i>May 26, 2022
                        </span>
                      </div>
                    </div>
                    <div className="recent-post-widget">
                      <div className="post-img">
                        <a href="blog-single">
                          <img src={blogfour} alt="" />
                        </a>
                      </div>
                      <div className="post-desc">
                        <a href="blog-single">
                          Construction executive holds a very special place
                        </a>
                        <span className="date-post">
                          {" "}
                          <i className="fi fi-rr-calendar"></i>May 26, 2022
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="categories mb-50">
                    <div className="widget-title mb-15">
                      <h3 className="title">Categories</h3>
                    </div>
                    <ul>
                      <li>
                        <a href="/">Commercial</a>
                      </li>
                      <li>
                        <a href="/">Educational</a>
                      </li>
                      <li>
                        <a href="/">Residential</a>
                      </li>
                    </ul>
                  </div>
                  <div className="tags-cloud">
                    <div className="widget-title pb-23">
                      <h3 className="title">Tags</h3>
                    </div>
                    <div className="tagcloud">
                      <a href="/">Architecture</a>
                      <a href="/">Building</a>
                      <a href="/">Business</a>
                      <a href="/">Dental</a>
                      <a href="/">Design</a>
                      <a href="/">Kaouwa</a>
                      <a href="/">Real Estates</a>
                      <a href="/">World</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Sliderabout

import React from "react"
import Silder from "./slider"
import Blog from "./blog"

const homePage = () => {
  return (
    <>
      <Silder />
      <Blog />
    </>
  )
}

export default homePage
